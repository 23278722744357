<template>
  <div><h1>任务调度列表</h1>
    <router-view /></div>

</template>

<script>
export default {
  name: "taskSchedulingList"
}
</script>

<style scoped>

</style>